import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import OverviewContent from '../components/OverviewContent';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityKubernetesContent } from '@internal/plugin-kubernetes';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  hasTechDoc,
  hasKubernetes,
  hasSonarqube,
  hasGitHubReleases,
  hasCloudflareCache,
} from '../../utils';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { GithubEntityRelease } from '@internal/backstage-plugin-github-entity-release';
import { CloudflareCacheManagementPage } from '@internal/backstage-plugin-cloudflare-cache-management';

const BaseEntityRoutes = (
  <>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs" if={hasTechDoc}>
      <EntityTechdocsContent>
        <TechDocsAddons>
          <ReportIssue />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/ci-cd"
      title="CI/CD"
      if={isGithubActionsAvailable}
    >
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
          <EntityGithubActionsContent />
        </EntitySwitch.Case>

        <EntitySwitch.Case>
          <EmptyState
            title="No CI/CD available for this entity"
            missing="info"
            description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
            action={
              <Button
                variant="contained"
                color="primary"
                href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
              >
                Read more
              </Button>
            }
          />
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/github-releases"
      title="Releases"
      if={hasGitHubReleases}
    >
      <GithubEntityRelease />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/cloudflare"
      title="Cloudflare"
      if={hasCloudflareCache}
    >
      <CloudflareCacheManagementPage />
    </EntityLayout.Route>
  </>
);

const DependenciesRoute = (
  <EntityLayout.Route path="/dependencies" title="Dependencies">
    <Grid container spacing={3} alignItems="stretch">
      <Grid xs={12}>
        <EntityDependsOnComponentsCard variant="gridItem" />
      </Grid>

      <Grid xs={12}>
        <EntityDependsOnResourcesCard variant="gridItem" />
      </Grid>
    </Grid>
  </EntityLayout.Route>
);

const KubernetesRoute = (
  <EntityLayout.Route path="/kubernetes" title="Kubernetes" if={hasKubernetes}>
    <EntityKubernetesContent refreshIntervalMs={30000} />
  </EntityLayout.Route>
);

const TestingSuitesRoutes = (
  <>
    <EntityLayout.Route path="/sonarqube" title="Sonarqube" if={hasSonarqube}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid md={12}>
          <EntitySonarQubeCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </>
);

export {
  BaseEntityRoutes,
  DependenciesRoute,
  KubernetesRoute,
  TestingSuitesRoutes,
};
